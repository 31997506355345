<template>
  <div>
    <b-card no-body class="mb-2">
      <div>
        <filter-slot
          :filter="visibleFilters"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reset-all-filters="resetAllFilters()"
          @reload="$refs.clientsList.refresh()"
        >
          <template #table>
            <b-table
              ref="clientsList"
              slot="table"
              :busy.sync="isBusy"
              :fields="visibleFields"
              :items="search"
              :filter="visibleFilters"
              :per-page="paginate.perPage"
              :current-page="paginate.currentPage"
              no-provider-filtering
              responsive="sm"
              small
              show-empty
              sticky-header="65vh"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(lead_name)="data">
                <div
                  class="d-flex flex-column justify-content-center align-items-start"
                >
                  <p class="mb-0 font-weight-bold text-info">
                    {{ data.item.lead_name }}
                  </p>

                  <p class="mb-0">
                    {{ data.item.mobile }}
                  </p>
                   <b-badge v-if="data.item.claims != 0" variant="light-info">
                    IN CLAIM ({{ data.item.claims }})
                  </b-badge>
                </div>
              </template>
              <template #cell(account)="data">
                <div
                  class="d-flex flex-column justify-content-center align-items-start"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    style="padding-bottom: 4px"
                  >
                    <router-link
                      class
                      target="_blank"
                      :to="`/quality/clients/account/${account.account_id}`"
                      >{{ account.account }}</router-link
                    >
                  </span>
                </div>
              </template>
              <template #cell(status)="data">
                <div
                  class="d-flex flex-column justify-content-center align-items-start"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    class="d-flex justify-content-between align-items-center"
                    style="padding-bottom: 4px"
                  >
                    <feather-icon
                      v-if="account.status === 1"
                      icon="CircleIcon"
                      size="13"
                      :style="`color: #00CC00; border-color: #00CC00; background: #00CC00; border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                    />
                    <div
                      v-if="account.status === 2"
                      class="client-status client-status-hold1"
                      :style="`top: 50%;margin-right: 5px; background: ${
                        account.validate_sp === 2 ? 'red' : ''
                      }`"
                    />
                    <feather-icon
                      v-if="account.status === 3"
                      icon="CircleIcon"
                      size="13"
                      :style="`color: #0066FF; border-color: #0066FF; background: #0066FF; border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                    />
                    <feather-icon
                      v-if="
                        account.status == 4 ||
                        account.status == 5 ||
                        account.status == 6
                      "
                      icon="CircleIcon"
                      size="13"
                      :style="`color: red; border-color: red; background: red; border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                    />
                    <span>
                      {{
                        account.status == 1
                          ? "Active"
                          : account.status == 2
                          ? "Hold"
                          : account.status == 3
                          ? "Transition"
                          : account.status == 4
                          ? "Canceled"
                          : account.status == 5
                          ? "Loyal"
                          : "Closed"
                      }}
                    </span>
                  </span>
                </div>
              </template>
              <template #cell(fee_charges)="data">
                <div
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  class="d-flex flex-column justify-content-center align-items-start"
                  style="padding-bottom: 4px"
                >
                  ${{ account.charges }}
                </div>
              </template>
              <template #cell(mp)="data">
                <div
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  class="d-flex flex-column justify-content-start align-items-start"
                  style="padding-bottom: 4px"
                >
                  ${{ account.monthly_amount }}
                </div>
              </template>
              <template #cell(lp)="data">
                <div
                  class="d-flex flex-column justify-content-start align-items-start"
                  style="position: relative"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    style="display: flex"
                  >
                    <b-img
                      v-if="account.state_lp == 1"
                      :src="`${baseImg}/assets/images/paragon/last_payment/lastpayment-out.ico`"
                      style="
                        margin-right: 5px;
                        height: 15px;
                        margin-top: 2px;
                        width: 15px;
                      "
                    />
                    <b-img
                      v-else
                      :src="`${baseImg}/assets/images/paragon/last_payment/lastpayment-in.ico`"
                      style="
                        margin-right: 5px;
                        height: 15px;
                        margin-top: 2px;
                        width: 15px;
                      "
                    />
                    {{
                      account.last_payment == "-" ? "" : account.last_payment
                    }}
                  </span>
                </div>
              </template>
              <template #cell(pt)="data">
                <div>
                  <ul style="padding-left: 0px; margin-bottom: 0px">
                    <li
                      v-for="(account, index) in data.item.accounts"
                      :key="index"
                      style="list-style: none"
                    >
                      <status-payment :account="account" />
                    </li>
                  </ul>
                </div>
              </template>
              <template #cell(advisor)="data">
                <div
                  class="d-flex flex-column justify-content-start align-items-start"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    style="padding-bottom: 4px"
                  >
                    <feather-icon
                      v-if="account.state_advisor === 1"
                      icon="CircleIcon"
                      size="13"
                      :style="`color: #00CC00; border-color: #00CC00; background: #00CC00; border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                    />
                    <feather-icon
                      v-if="account.state_advisor === 2"
                      icon="CircleIcon"
                      size="13"
                      :style="`color: #00CC00; border-color: #00CC00; background: #00CC00; border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                    />
                    <feather-icon
                      v-if="account.state_advisor === 0"
                      icon="CircleIcon"
                      size="13"
                      :style="`color: rgb(204, 204, 204); border-color: #00CC00; background: rgb(204, 204, 204); border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                    />
                    <span>{{ account.advisor_name }}</span></span
                  >
                </div>
              </template>
              <template #cell(ln)="data">
                <div
                  class="d-flex flex-column justify-content-start align-items-start"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    :style="
                      account.s_note <= 1
                        ? isDarkSkin
                          ? 'color:#fff'
                          : 'color:#6d6b7b'
                        : account.s_note <= 2
                        ? 'color: #FF8000'
                        : 'color:#FF0000'
                    "
                    style="padding-bottom: 4px"
                    >{{ account.last_note }}
                  </span>
                </div>
              </template>
              <template #cell(ut)="data">
                <div
                  class="d-flex flex-column justify-content-start align-items-start"
                  style="margin-left: 10px"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    :style="
                      account.task <= 5
                        ? 'color:#000000; position: relative;'
                        : account.task <= 10
                        ? 'color: #FF8000; position: relative;'
                        : 'color:#FF0000; position: relative;'
                    "
                  >
                    <b-img
                      :src="`${baseImg}/assets/images/paragon/tasks/uncompletetasks.ico`"
                      style="
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                      "
                    />
                    <span
                      style="font-size: 11px"
                      :style="isDarkSkin ? 'color: #fff' : ''"
                      >{{ account.task }}</span
                    >
                  </span>
                </div>
              </template>
              <template #cell(creation_date)="data">
                <ul style="padding-left: 0px; margin-bottom: 0px">
                  <li
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    style="list-style: none"
                  >
                    {{ account.created_at | myGlobal }}
                  </li>
                </ul>
              </template>
            </b-table>
          </template>
        </filter-slot>
      </div>
    </b-card>
  </div>
</template>

<script>
import dataFields from "@/views/quality/views/clients/components/fields.data";
import dataFilters from "@/views/quality/views/clients/components/filters.data";
import ClientService from "@/views/quality/views/clients/services/client.service.js";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    StatusPayment,
  },
  data() {
    return {
      filter: dataFilters,
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "account_id",
      startPage: null,
      toPage: null,
      isBusy: false,
      items: [],
      fields: dataFields,
      order: "desc",
      usersServices: [],
      programsAll: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      this.fields[8].visible =
        this.currentUser.role_id !== 3 || this.moduleId === 2;
      this.fields[9].visible =
        (this.moduleId !== 2 && this.moduleId !== 4 && this.moduleId !== 6) ||
        (this.moduleId === 6 && this.currentUser.role_id !== 4);
      return this.fields.filter((column) => column.visible);
    },
    visibleFilters() {
      this.filter[2].visible = true;

      if (this.filter[2].model != null) {
        this.filter[3].visible = true;
        this.usersAllPrograms();
      } else {
        this.filter[3].visible = false;
      }

      return this.filter.filter((column) => column.visible);
    },
    paymentType: {
      get() {
        return this.filter[5].model;
      },
      set(value) {
        this.filter[5].model = value;
      },
    },
    paymentDay: {
      get() {
        return this.filter[6].model;
      },
      set(value) {
        this.filter[6].visible = value;
      },
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
    paymentType(newVal) {
      if (newVal === 1) {
        this.paymentDay = true;
      } else {
        this.paymentDay = false;
        this.filter[6].model = 0;
      }
    },
  },
  created() {
    this.allPrograms();
  },
  methods: {
    async search(ctx) {
      try {
        let orderBy = 5;
        let sortDirection = "desc";
        if (ctx.sortBy === "lead_name") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 2;
        } else if (ctx.sortBy === "account") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 4;
        } else if (ctx.sortBy === "fee_charges") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 42;
        } else if (ctx.sortBy === "pt") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 48;
        } else if (ctx.sortBy === "mp") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 43;
        } else if (ctx.sortBy === "creation_date") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 5;
        }
        const params = {
          per_page: this.paginate.perPage,
          orderby: orderBy,
          order: sortDirection,
          from: this.filter[0].model,
          to: this.filter[1].model,
          text: this.filterPrincipal.model,
          state: this.state,
          status: this.filter[4].model,
          advisor: this.filter[3].model,
          type: this.paymentType,
          day: this.paymentDay,
          rol_id: 1,
          program: this.filter[2].model,
          list: this.$route.meta.list_table,
          showall: true,
        };
        const data = await ClientService.getClients(params, ctx.currentPage);
        data.data.data.map((data) => {
          // eslint-disable-next-line no-param-reassign
          data.accounts = JSON.parse(data.accounts);
          data.accounts.map((val) => {
            if (val.charges === null) val.charges = 0;
          });
        });
        const items = data.data.data;
        // Must return an array of items or an empty array if an error occurred
        this.items = items;
        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalData = data.data.total;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;

        return this.items;
      } catch (error) {
        this.showErrorSwal(error);
        return [];
      }
    },
    resetAllFilters() {
      this.filter.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.clientsList.refresh();
    },
    async usersAllPrograms() {
      try {
        const params = {
          idmodule: this.moduleId,
          iduser: this.currentUser.user_id,
          idrole: this.currentUser.role_id,
        };
        const data = await ClientService.usersPrograms(params);
        this.filter[2].options = data;
      } catch (error) {
        console.log(error);
      }
    },

    async allPrograms() {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.moduleId,
        });
        this.usersServices = data.data;
        this.usersServices.unshift({ id: 1, user_name: "CEO" });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
